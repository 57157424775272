:root {
    --white: #FFFFFF;
    --black: #111017;
    --dark-grey: #181621;
    --grey: #cecece;
    --purple: #5F5BFF;
    --light-grey: #1B1B27;
    --sky-blue: #00B2EB;
}

.landing-page {
    background: url('../images/gradient-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: var(--black);
}

header .nav-link {
    color: var(--white) !important;
}

header .nav-link:hover {
    color: var(--grey) !important;
}

.cta {
    background: radial-gradient(108.21% 187.29% at -4.56% 9.81%, #00AFE6 0%, #5F5BFF 100%);
    border-radius: 12px;
    padding: 0.5rem 1.2rem;
    color: var(--white) !important;
    text-decoration: none;
}

.cta:hover {
    background: radial-gradient(108.21% 187.29% at -4.56% 9.81%, #5F5BFF 0%, #00AFE6 100%);
}

.cta.secondary {
    background: transparent;
    border: 2px solid var(--purple);
}

.cta.secondary:hover {
    background: #5e5bff18;
    border: 2px solid var(--purple);
    transition: 0.2s all ease;
}

.left-hero {
    position: relative;
}

/* .left-hero::before{
      content: url(../images/bg-left.png);
      position: absolute;
      right: 0px;
      top: -100%;
      z-index: -1;
      opacity: 0.5;
  } */
.btn-pair {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.hero-row {
    align-items: center;
}

.left-hero {
    position: relative;
}

.hero .left-hero span {
    height: 315vh;
    min-height: 100%;
}

.hero .left-hero span::before {
    content: "";
    width: 2px;
    height: inherit;
    background-color: #00B2EB;
    position: absolute;
    left: -20px;
    top: 35px;
    z-index: 1;
}

.circle-big::before {
    content: url(../images/big-circle.png);
    position: absolute;
    left: -29px;
    z-index: 1;
}

.four-box {
    display: grid;
    max-width: 60vw;
    color: var(--white);
    grid-template-columns: 25% 25% 25% 25%;
}

.sm-c-line {
    position: relative;
}

.sm-c-line::before {
    content: url(../images/small-circle.png);
    position: absolute;
    left: -38px;
    z-index: 999;
    top: 6px;
}

.sm-c::before {
    content: url(../images/small-circle-with-line.png);
    position: absolute;
    left: -40px;
    top: -25px;

}

.last-dif-line::before {
    content: url(../images/last-dif-line.png);
    position: absolute;
    left: -40px;
    top: -25px;
}

.sm-c {
    position: relative;
    color: var(--white);
}

.four-box {
    position: relative;
}

.four-box::before {
    content: "";
    width: 74.45%;
    height: 2px;
    background-color: #00B2EB;
    position: absolute;
    left: -32px;
    top: -25px;
}

.section-2-custom-set::before {
    content: url(../images/big-circle.png);
    position: absolute;
    left: -41px;
    z-index: 1;
    top: -4px;
}

h1.section-2-custom-set,
h2.section-2-custom-set {
    font-size: 4rem !important;
    background: -webkit-linear-gradient(#61D8FB, #6B81FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-2 {
    margin-top: 5rem;
}

.proxy-network-details .left-line {
    position: absolute;
    left: -32.7px;
    top: 0px;
}

.proxy-network-details {
    position: relative;
}

.detail-box {
    background-color: var(--dark-grey);
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 70%;
    color: var(--white);
    border-radius: 16px;
    position: relative;
    margin-bottom: 35px;
    padding: 2rem;
}

.shadow-box {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #00B2EB;
    border-radius: 16px;
    z-index: -1;
    left: 5px;
    top: 5px;
}

.box-img-part {
    min-width: 120px;
    text-align: center;
}

.box-img-part img {
    max-width: 120px;
}

.details-boxes {
    padding-top: 50px;
    padding-left: 150px;
}

.detail-box.two {
    margin-left: 10%;
}

.detail-box.three {
    margin-left: 20%;
}

.pricing-details .nav-pills {
    background-color: var(--light-grey);
    border-radius: 120px;
}

.pricing-details .nav-pills .nav-link.active {
    border-radius: 120px;
    background-color: var(--sky-blue);
}

.pricing-details .nav-pills .nav-link {
    padding: 0.5rem 2.5rem;
    color: var(--white);
    border-radius: 120px;
}

.top-tab-section {
    display: flex;
    align-items: center;
    gap: 20px;
    color: var(--white);
    flex-wrap: wrap;
}

.small-circle::before {
    content: url(../images/small-circle.png);
    position: absolute;
    left: -32px;
    top: 9px;
}

.small-circle {
    position: relative;
}

.pricing-details {
    padding-left: 30px;
    /* padding-top: 80px; */
}

.pricing-details.home {
    padding-left: 30px;
    padding-top: 100px;
}

.price-box {
    padding: 2rem;
    background-color: var(--light-grey);
    color: var(--white);
    border-radius: 14px;
    height: 100%;
}

.main-price {
    padding: 0.5rem 0rem;
    background-color: var(--black);
    text-align: center;
    font-size: 1.5rem;
    color: var(--white);
    border-radius: 12px;
}

.price-feature .bullet li::before {
    content: url(../images/bullet.png);
    position: absolute;
    left: -28px;
}

.price-feature .bullet li.red-bullet::before {
    content: url(../images/red-bullet.png);
    position: absolute;
    left: -28px;
}

.price-feature .bullet li {
    position: relative;
    list-style: none;
    margin-bottom: 5px;
    color: var(--white);
}

.price-feature .bullet li.red-bullet::after {
    content: url(../images/botto-red-line.png);
    position: absolute;
    left: -5px;
    top: 10px;
}

.plan-select select {
    background-color: var(--dark-grey);
    border: 1px solid #29293F;
    padding: 0.7rem 1rem;
    color: var(--white);
    width: 100%;
    text-align: center;
    border-radius: 12px;
}

.purchase-btn {
    background-color: var(--purple);
    display: block;
    padding: 0.5rem 1rem;
    text-align: center;
    color: var(--white) !important;
    text-decoration: none;
    border-radius: 12px;
    border: none;
}

.purchase-btn:hover {
    background-color: #4541FF;
    transition: 0.2s all ease-in-out;
}

.faq {
    background-color: var(--light-grey);
    border-radius: 12px;
    padding: 1.7rem 1.5rem;
    color: var(--white);
    margin-bottom: 1.5rem;
}

.faq h2 {
    font-weight: 500;
    font-size: 1.8rem;
    margin: 0;
    display: flex;
    justify-content: space-between;
}

.faq h2 img {
    transform: rotate(0deg);
    transition: transform 0.2s ease;
}

.faq.open h2 img { 
    transform: rotate(90deg);
}

.faq .answer {
    margin: 0;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
}

.faq.open .answer {
    margin-top: 0.5rem;
    max-height: 1000px;
    opacity: 1;
}

.faq p {
    color: #AFB0C8;
}

.building {
    position: absolute;
    right: 0px;
    top: -160px;
    max-width: 350px;
}

.mt-100 {
    margin-top: 100px;
}

h1.section-2-custom-set,
h2.section-2-custom-set {
    font-size: 2.5rem !important;
    color: var(--white) !important;
    background: unset;
    -webkit-text-fill-color: unset !important;
}

.mt-175 {
    margin-top: 175px;
}

.social-icons a {
    color: var(--white);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
}

.social-icons a:hover {
    color: var(--purple);
    transition: 0.2s all ease-in-out;
}

.social-icons a:hover svg path {
    fill: var(--purple);
    transition: 0.2s all ease-in-out;
}

footer a {
    text-decoration: none;
    color: var(--white);
    margin-bottom: 8px;
    display: block;
}

footer a:hover {
    color: var(--purple);
    transition: 0.2s all ease-in-out;
}

footer h4 {
    color: var(--sky-blue);
}

footer {
    background-color: #0D0C12;
    padding: 3rem 0rem;
}

.landing-page .liner-g {
    background-color: var(--light-grey);
}

.landing-page .payout-modal {
    padding: 25px 30px 10px !important;
    border-radius: 14px;
}

.landing-page .btn-close {
    background: transparent url('../images/close.svg');
}

.landing-page .process-details {
    background-color: #111017;
    border-radius: 12px;
}

.grey_400_bg {
    background-color: #29293F;
}

.grey_600_text {
    color: #4F5069;
}

.grey_700_text {
    color: #AFB0C8;
}

.main-d-container {
    border-radius: 20px;
    border-top: 5px solid var(--purple);
    margin-top: 100px;
    padding: 180px 60px;
    background: var(--black);
    box-shadow: 1px 5px 40px -8px rgba(95, 91, 255, 0.75);
    -webkit-box-shadow: 1px 5px 40px -8px rgba(95, 91, 255, 0.75);
    -moz-box-shadow: 1px 5px 40px -8px rgba(95, 91, 255, 0.75);
}

.main-d-container h1 {
    font-size: 3.25rem;
    color: var(--white);
}

.main-d-container p {
    font-size: 1.125rem;
    color: var(--white);
}

.c-proxies {
    border-radius: 20px;
    border-top: 5px solid var(--sky-blue);
    padding: 40px;
    margin-top: 100px;
    background: var(--black);
    color: var(--white);
    box-shadow: 1px 5px 40px -8px rgba(0, 175, 230, 0.75);
    -webkit-box-shadow: 1px 5px 40px -8px rgba(0, 175, 230, 0.75);
    -moz-box-shadow: 1px 5px 40px -8px rgba(0, 175, 230, 0.75);
}

.c-price {
    font-size: 2.25rem;
}

.m-sty {
    color: #AFB0C8;
    font-size: 20px;
    padding-left: 10px;
    position: relative;
    top: -6px;
}

.m-sty1 {
    font-size: 15px !important;
}

.b-main {
    border-radius: 20px;
    padding: 24px 30px;
    background: #0D0C12;
    margin: 0px;
    margin-top: 40px;
}

.db-details {
    color: var(--white);
}

.db-details h2 {
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 4px;
}

.db-details p {
    color: #AFB0C8;
    margin-bottom: 0;
    padding-left: 20px;
}

.d-dot {
    display: inline-block;
    background: var(--sky-blue);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 5px;
}

.class-680 {
    display: none;
}

.class-580 {
    display: none;
}

.class-gt-680 {
    display: flex;
}

.class-nt-580 {
    display: none;
}

/* .dropdown-menu {
    background-color: #1A1A1A;
}

.dropdown-item {
    color: white !important;
} */

@media (max-width: 1440px) {
    .hero .left-hero span::before {
        height: inherit;
    }

}

@media (max-width: 1366px) {
    .hero .left-hero span::before {
        height: inherit;
    }

}

@media (max-width: 1024px) {
    .hero .left-hero span::before {
        height: inherit;
    }

    .detail-box {
        max-width: 85%;
    }

    .details-boxes {
        padding-top: 110px;
        padding-left: 100px;
    }

    .building {
        position: absolute;
        right: 0px;
        top: -160px;
        max-width: 350px;
        z-index: -1;
        opacity: 0.5;
    }

    .hero .left-hero span::before {
        height: inherit;
    }

}

@media (max-width: 992px) {
    .class-gt-680 {
        display: none;
    }

    .class-680 {
        display: flex;
    }

    .class-580 {
        display: none;
    }

    .class-nt-580 {
        display: inline;
    }

    .four-box {
        max-width: 100%;
        margin-top: 55px;
    }

    .detail-box {
        max-width: 85%;
    }

    .proxy-network-details .left-line {
        top: 0px;
    }

    .details-boxes {
        padding-top: 50px;
        padding-left: 80px;
    }

    .price-box {
        margin-bottom: 1rem;
    }
}

@media (max-width: 768px) {
    body {
        font-size: 85%;
    }

    .four-box::before {
        width: 83.45%;
    }

    .four-box {
        gap: 17px;
    }

    .left-line {
        display: none;
    }

    .details-boxes {
        padding-top: 20px;
        padding-left: unset;
    }

    .detail-box.two {
        margin-left: unset;
    }

    .detail-box.three {
        margin-left: unset;
    }

    .detail-box {
        max-width: 100%;
    }

    .pricing-details {
        padding-left: 0px;
        /* padding-top: 5px; */
    }

    .top-tab-section h2 {
        display: none;
    }

    .main-title {
        font-size: 1.5rem;
    }

    .left-hero {
        padding-top: 15px;
    }

    .section-2-custom-set::before {
        top: 1px;
    }

    h1.section-2-custom-set,
    h2.section-2-custom-set {
        font-size: 1.5rem !important;
    }

    .top-tab-section.small-circle::before {
        left: -38px;
        z-index: 9999;
    }

    .hero .left-hero span::before {
        height: inherit;
    }

    .cta {
        padding-left: 1.2rem !important;
        padding-right: 1.2rem !important;
    }

    .box-img-part {
        min-width: 90px;
        text-align: center;
    }

    .box-img-part img {
        max-width: 90px;
    }

    .c-footer-section {
        order: 5;
        width: 100%;
        margin-top: 25px;
        display: flex;
        gap: 15px;
    }

    .navbar-brand img {
        content: url(../images/small-logo.png);
    }

    .main-d-container {
        padding: 60px 40px;
    }

    .c-proxies {
        margin-top: 40px;
    }

    .b-main {
        padding: 0;
    }

    .db-details {
        padding: 10px 0;
    }
}

@media (max-width: 580px) {
    .class-gt-680 {
        display: none;
    }

    .class-680,
    .class-580 {
        display: flex;
    }

    .class-nt-580 {
        display: none;
    }

    .container {
        max-width: 440px;
    }

    .four-box::before {
        width: 85.80%;
    }

    .box h4 {
        font-size: 1.2rem !important;
    }

    .box-details-part h3 {
        font-size: 1.2rem !important;
    }

    .main-price {
        font-size: 1rem;
    }

    .price-box h2 {
        font-size: 1.5rem;
    }

    .faq h2 {
        font-size: 1.5rem;
    }

    .mt-100 {
        margin-top: 60px;
    }

    footer {
        padding: 1.5rem 0;
    }

    .landing-page .payout-modal {
        padding: 25px 20px 10px !important;
        border-radius: 14px;
    }
}

@media (max-width: 425px) {
    .container {
        max-width: 320px;
    }

    .productPage .container {
        max-width: 360px;
    }

    .four-box {

        grid-template-columns: 1fr 1fr;
    }

    .four-box::after {
        content: "";
        width: 56.45%;
        height: 2px;
        background-color: #00B2EB;
        position: absolute;
        left: -32px;
        top: 92px;
    }

    .four-box .box.sm-c:nth-child(2):before {
        content: url(../images/last-dif-line.png);
        position: absolute;
        left: -40px;
        top: -25px;
    }

    .sm-c::before {
        z-index: 9999;
    }

    .four-box {
        gap: 50px;
    }

    .four-box::before {
        width: 56.8%;
    }

    .box-details-part {
        padding: 0.5rem !important;
    }

    .detail-box {
        padding: 0.5rem;
    }

    .left-hero {
        width: 90%;
    }

    .hero-row .col-6:last-child {
        position: absolute;
        right: 0px;
    }

    .hero .left-hero span::before {
        height: inherit;
    }

    header .container {
        max-width: 390px;
    }

    .pricing-details .nav-pills .nav-link {
        padding: 0.5rem 2rem;
    }

    .main-d-container h1 {
        font-size: 2.25rem;
    }
}

.subheadline li {
    position: relative;
    list-style: none;
    margin-bottom: 10px;
    color: var(--white);
}

.subheadline li::before {
    content: url(../images/bullet.png);
    position: absolute;
    left: -28px;
}