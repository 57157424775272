:root {
  --light-blue: #F2F3F7;
  --main-white: #fbfbfb;
  --main-blue: #103A8D;
  --main-black: #2E2E3A;
  --main-sky-blue: #359BEC;
  --main-sky-blue-hover: #2390e9;
  --menu-active-bg: #7EB1ED;
  --menu-bg: #F1F3F9;
  --main-light-blue: #FAFCFF;
  --white-border: 3px solid #FFFFFF;
  --bg-shadow-lg: -3px 6px 15px 3px #2c3d7928;
  --bg-shadow-sm: 0px 6px 8px 3px #2c3d7917;
  --bg-gradient: linear-gradient(#F9F8FD, #FFFFFF);
}

a {
  color: var(--white);
}

a:hover {
  color: var(--main-white);
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

body {
  font-family: 'Lato';
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

.login-page {
  background: url('../images/body-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

.fixed-top {
  background-color: var(--black) !important;
}

.form-part {
  background-color: var(--main-light-blue);
  max-width: 675px;
  border-color: var(--main-light-blue);
  border: var(--white-border);
  margin: 100px auto 0px;
  box-shadow: var(--bg-shadow-lg);
  background-image: url(../images/form-bg.png);
  background-size: 350px;
  background-position: -30% 110%;
  background-repeat: no-repeat;
  border-radius: 8px;

}

.form-inner {
  max-width: 450px;
  margin: 50px auto 60px;
}

/* .login-page {
  font-family: 'Lato' !important;
  background: url(../images/body-bg.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center top !important;
  background-color: var(--white) !important;
} */

.social {
  justify-content: space-around;
  gap: 15px;
  flex-wrap: wrap;
}

.social a {
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  max-width: 220px;
  padding: 8px 15px;
  border-radius: 50px;
  box-shadow: var(--bg-shadow-sm);
  margin-top: 8px;
}

.google-login a {
  background-color: var(--main-white);
  color: var(--main-blue);
}

.discord-login a {
  background-color: #596bef;
  color: white;
}

.social img {
  width: 40px;
}

.form-inner h2 {
  font-weight: bold;
  color: var(--main-blue);
}

.form-inner p {

  color: var(--main-blue);
}

.hr-line {
  margin-top: 35px;
  margin-bottom: 50px;
}

.forgot-pass,
.form-check-label {
  color: var(--main-blue);
  text-decoration: none;

}

.form-field .form-control {
  background: var(--main-white);
  border: none;
  padding: 12px 25px;
  box-shadow: var(--bg-shadow-sm);
  border-radius: 8px;
  border: var(--white-border);
  color: var(--main-blue);
}

.form-field .form-control::placeholder {
  color: var(--main-blue);
  font-size: 14px;
}

.form-cta-btn a {
  background: var(--main-sky-blue);
  color: var(--main-white);
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 10px;
  border-radius: 50px;
  font-size: 18px;
}

.form-cta-btn a:hover {
  background: var(--main-sky-blue-hover);
  transition: all 0.22 ease-in-out;
}

.arrow-left {
  margin-left: -60px;
  margin-right: 8px;
}

.arrow-left:active {
  opacity: 0.8;
}

.forgot-pass-part {
  margin-top: 30vh;
}

.form-part.fp {
  max-width: 550px;
}

.form-inner.fp {
  max-width: 370px;
}

.list-group-item {
  background: transparent;
  border: none;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  column-gap: 15px;
  justify-content: flex-start;
}

.list-group-item.active {
  background: transparent;
  border: none;
}

.list-group-item.active>div {
  background: var(--menu-active-bg);
  padding: 8px;
  border-radius: 12px;
}

.list-group-item div {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: visible;
  opacity: 1;
  transition: all 0.35s ease-in-out;
}

.list-group-item span {
  color: var(--main-blue);
  font-size: 18px;
  font-weight: 600;
  min-width: max-content;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in-out;
}

.menu-sub a {
  display: block;
  color: var(--main-blue);
  text-decoration: none;
  margin-bottom: 8px;
  font-weight: normal;

}

.menu-sub a:hover {
  color: var(--main-sky-blue-hover);
  transition: all 0.35s ease-in-out;
}

.menu-sub {
  padding-left: 60px;
}

.top-nav-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
  margin-bottom: 35px;
}

.top-nav #menu-toggle {
  display: none;
}

.profile img {
  width: 32px;
}

.profile a {
  display: flex;
  align-items: center;
  gap: 8px;
}

.profile span,
.profile a {
  color: var(--main-black);
  text-decoration: none;
}

.profile button {
  border: 0;
}

.blackCheckout .dash-head {
  margin-bottom: 30px;
}

.dash-head {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;
}

.dash-head h2,
.dash-head h4 {
  font-weight: 600;
  color: var(--main-sky-blue);
}

.dash-nav .nav-link.active {
  color: var(--main-blue);
  background-color: transparent;
  font-weight: 600;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.dash-nav .nav-link {
  color: var(--main-blue);
}

.dash-nav .nav-link.active::after {
  content: url(../images/active-nav-badge.png);
  position: absolute;
  bottom: -30px;
  left: calc(50% - 22px);
}

.dash-bg {
  background: var(--light-blue);
}

.form-field .form-select {
  appearance: auto;
  background: var(--main-white);
  border: none;
  padding: 12px 15px;
  box-shadow: var(--bg-shadow-sm);
  border-radius: 8px;
  height: 48px;
  border: var(--white-border);
}

.range-t {
  font-size: 15px;
  color: var(--main-blue);
  margin-top: 30px;
}

.range-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 23px;
}

.number-proxy {
  margin-top: 15px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.number-proxy div {
  color: var(--main-blue);
  font-size: 18px;
  font-weight: 500;
}

.number-proxy-box {
  width: 100%;
}

.number-proxy-box input {
  width: 100%;
}

.range-slider__value {
  font-size: 20px;
  padding: 4px 20px;
  background: var(--main-white);
  border: var(--white-border);
  box-sizing: border-box;
  box-shadow: var(--bg-shadow-sm);
  border-radius: 4px;
  color: var(--main-blue);
  text-align: center;
}

.blackCheckout .range-slider__value {
  font-size: 1.1rem;
  width: 60%;
  background-color: #111017;
  padding: 8px 10px;
  border: 1px solid #29293F;
  box-shadow: none;
  border-radius: 12px;
  font-weight: lighter;
}

.proxy-details-part {
  background-color: var(--main-white);
  border-radius: 16px;
  box-shadow: var(--bg-shadow-sm);
  max-height: 250px;
  overflow-y: scroll;
  border: var(--white-border);
}

textarea,
input {
  outline: none;
  color: var(--main-blue);
  padding: 4px 25px;
}

.proxy-details-part>div {
  color: var(--main-blue);
  padding: 4px 25px;
}

.proxy-details-part>div:hover {
  background: rgba(53, 155, 236, 0.1);
}

.proxy-details-part::-webkit-scrollbar {
  width: 10px;
}

.proxy-details-part::-webkit-scrollbar-track {
  background: transparent;
}

.proxy-details-part::-webkit-scrollbar-thumb {
  background: #E8F2FA;
  border-radius: 8px;
}

.proxy-details-part::-webkit-scrollbar-thumb:hover {
  background: #E8F2FA;
}

.cta-white {
  background-color: var(--main-white);
  color: var(--main-sky-blue);
  text-decoration: none;
  padding: 12px 25px;
  border-radius: 50px;
  box-shadow: var(--bg-shadow-sm);
  font-size: 18px;
}

.cta-white:hover {
  background-color: var(--main-light-blue);
  color: var(--main-sky-blue);
}

.plan-wrapper {
  background-image: var(--bg-gradient);
  box-shadow: var(--bg-shadow-sm);
  border-radius: 12px;
  padding: 5px 10px;
  border: var(--white-border);
  margin-bottom: 15px;
}

.plan-details h4 {
  font-weight: 600;
  color: var(--main-blue);
  margin-bottom: 15px;
}

.plan-details h5 {
  color: var(--main-blue);
}

.plan-wrapper {
  display: flex;
  align-items: center;
  gap: 25px;
  /* max-width: 550px; */
  position: relative;
}

.plan-bg {
  position: absolute;
  right: 10px;
  top: 10px;
}

.plan-svg {
  z-index: 99;
  position: relative;
}

.plan-details {
  position: relative;
  z-index: 99;
}

#page-content-wrapper.extra-p {
  padding: 20px 80px;
}

.sub-part {
  color: var(--main-blue);
}

.sub-part h5 {
  color: var(--main-blue);
  font-weight: 700;
}

.sub-part-nav .nav-link {
  background: var(--main-white);
  box-shadow: var(--bg-shadow-sm);
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  border-radius: 11px !important;
  padding: 15px 10px;
  min-width: 110px;
  max-width: 23%;
  width: 23%;
  text-align: center;
}

.sub-part-nav .nav-link {
  border: 2px solid transparent;
}

.sub-part-nav .nav-link.active {
  border: 2px solid var(--main-sky-blue);
}

.proxy-sub-details {
  display: flex;
  color: var(--main-blue);
  gap: 40px;
}

.swap-btn-outer {
  padding: 10px 15px;
  background-color: var(--main-white);
  height: max-content;
  box-shadow: var(--bg-shadow-sm);
  border: var(--white-border);
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

.swap-btn-outer a {
  color: var(--main-blue);
  text-decoration: none;
}

.swap-btn-outer a:hover {
  color: var(--main-blue);
  text-decoration: none;
}

.swap-btn-outer:active {
  opacity: 0.8;
  transition: 0.1s al ease;
}

.proxy-sub-details h2 {
  font-weight: 600;
}

.form-field .col-form-label {
  padding-top: calc(.375rem + 5px);
  padding-bottom: calc(.375rem + 5px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  color: var(--main-blue);
}

.form-field .form-control::placeholder {
  color: var(--main-blue);
}

.change-pass a {
  color: var(--main-sky-blue);
}

.password-m .modal-body {
  background-color: var(--light-blue);
  padding: 25px 40px;
  position: relative;
  box-shadow: var(--bg-shadow-sm);
}

.password-m .modal-content,
.password-m .modal-body {
  border-radius: 12px;
  border: none;
}

.pop-bg-img {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 1;
}

.up-z {
  z-index: 99;
  position: relative;
}

.modal-backdrop.show {
  /* opacity: 0.5; */
  /* filter: blur(8px); */
  opacity: 1;
  background-color: rgba(22, 22, 22, 0.055);
  backdrop-filter: blur(4px);
  transition: all 0.2s ease-in-out;
}

.modal-backdrop {
  /* opacity: 0.5; */
  /* filter: blur(8px); */
  opacity: 0;
  background-color: rgba(22, 22, 22, 0);
  backdrop-filter: blur(0px);
  transition: all 0.2s ease-in-out;
}

.change-p-out {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.profile-pic {
  background-color: var(--main-white);
  box-shadow: var(--bg-shadow-sm);
  border: var(--white-border);
  text-align: center;
  width: 100%;
}

.uploda-pic a,
.uploda-pic h6 {
  color: var(--main-blue);
}

.uploda-pic a:hover {
  color: var(--main-blue);
}

.text-blue {
  color: var(--main-blue) !important;
}

.pop-close-btn {
  position: absolute;
  right: 25px;
  opacity: 0.3;
  border: none;
  box-shadow: none !important;
  width: 16px;
  height: 16px;
}

.process-details {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 15px;
}

.w-custom {
  width: 40px !important;
  height: 20px;
  margin-right: 15px;
}

.w-custom-2 {
  width: 5px !important;
  height: 25px;
  margin-right: 15px;
}

.form-check-input:checked {
  background-color: var(--main-sky-blue);
  border-color: var(--main-sky-blue-hover);
}

.blackCheckout .form-check-input:checked {
  background-color: #5F5BFF;
  border-color: #5F5BFF;
}

.whiteCheckout .liner-g {
  background-image: linear-gradient(#FFFFFF, #F9F8FD);
  ;
}

.blackCheckout .modal-content {
  background-color: transparent;
  border: none;
}

.blackCheckout .btn-close {
  background: url('../images/close.png') !important;
  border-radius: 0;
  opacity: 1.0 !important;
}

.blackCheckout #applyCouponBtn {
  border-radius: 12px;
  border: none;
  /* padding: 8px 45px; */
  font-size: 1rem;
  width: 33%;
}

.red-cta {
  background-color: #F5757D;
  transition: all 0.2s ease-in-out;
}

.red-cta:hover {
  background-color: #f5545f;
  transition: all 0.2s ease-in-out;
}

.payout-modal {
  padding: 30px 60px !important;
}

.dash-tabs .nav-link {
  background-color: var(--main-white);
  border: 0.64977px solid var(--main-blue);
  border-radius: 28px;
  min-width: 75px;
  color: var(--main-blue) !important;
  transition: all 0.2s ease-in-out;
}

.dash-tabs .nav-link.active {
  background-color: #E8F5F9;
  border: 0.64977px solid var(--main-sky-blue);
  border-radius: 28px;
  min-width: 75px;
  color: var(--main-sky-blue) !important;
}

.dash-tabs .nav-tabs {
  gap: 10px;
}

.dash-box {
  background-color: var(--main-white);
  border: var(--white-border);
  padding: 20px 30px;
  box-shadow: var(--bg-shadow-sm);
  border-radius: 15px;
}

.purchase-storage .nav-link {
  background: url(../images/nav-bg.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  display: inline-block;
  min-width: 115px;
  min-height: 150px;
  border: var(--white-border);
  position: relative;
}

.purchase-storage .nav-link.active {
  background-image: linear-gradient(to top, #c0ecff5e, #ffffff62), url(../images/nav-bg.png);
  box-shadow: 0px 17px 14px rgba(10, 98, 230, 0.21);
  ;
}

.purchase-storage .nav-link.active::after {
  content: url(../images/green-icon.svg);
  position: absolute;
  top: 15px;
  right: 15px;
}

.proxy-g-bg {
  position: absolute;
  right: 10px;
  bottom: 0px;
  z-index: 1;
}

.gp-out {
  position: relative;
  z-index: 99;
}

.dash-box>.row {
  position: relative;
}

.plan-price-bg {
  background-image: url(../images/price-bg-part.png);
  background-size: contain;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.plan-nav .nav-link.active {
  background-color: var(--main-sky-blue);
  color: var(--main-white);
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
}

.plan-nav .nav-link {
  background-color: var(--main-white);
  color: var(--main-blue);
  border-radius: 50px;
  padding: 10px 25px;
  box-shadow: var(--bg-shadow-sm);
  transition: all 0.2s ease-in-out;
}

.plan-nav ul {
  gap: 15px;
  margin-top: 1rem;
}

.bullet li {
  list-style: none;
  margin-bottom: 8px;
  color: var(--main-blue);
}

.bullet li::before {
  content: url(../images/bullet-point.svg);
  padding-right: 8px;
}

.price-box-disc .form-select {
  height: 54px;
}

.plan-price-inner {
  background: var(--main-white);
  padding: 20px 30px 5px 30px;
  border: var(--white-border);
  box-shadow: var(--bg-shadow-sm);
  border-radius: 8px;
}

.small-navs .nav-link {
  padding: 4px 8px;
  font-size: 14px;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid var(--main-blue);
}

.small-navs .nav-link.active {
  border: 1px solid var(--main-sky-blue);
  border-radius: 4px;
}

.plan-blue {
  background: #2A3F89;
  color: var(--main-white) !important;
}

.plan-blue>* {
  color: var(--main-white) !important;
}

.plan-blue ul li {
  color: var(--main-white);
}

.plan-blue p {
  color: var(--main-white) !important;
}

.plan-blue ul li::before {
  color: var(--main-white);
  filter: brightness(15);
}

.dash-tabs .small-navs .nav-link {
  min-width: unset;
  border-radius: 8px;
}

.dash-tabs .small-navs .nav-link.active {
  background-color: var(--main-sky-blue);
  color: var(--main-white) !important;
}

.inner-isp-box {
  background: var(--main-white);
  border: var(--white-border);
  box-shadow: var(--bg-shadow-sm);
  padding: 15px 10px;
  border-radius: 8px;
}

.inner-isp-box.active {
  border-color: aquamarine;
}

.isp-img-top {
  position: absolute;
  right: 0px;
  top: 0px;
}

table th {
  color: var(--main-sky-blue);
}

table td {
  color: var(--main-blue);
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: #359aec18 !important;
}

.table-part .nav-pills {
  /* min-height: 103px; */
}

/* side nav */
#sidebar-wrapper {
  margin-left: -3px;
  transition: all 0.35s ease-in-out;
  cursor: pointer;
}

.menu-sub {
  display: none;
}

.toggled .menu-sub {
  display: block;
}

.toggled #mainContainer,
.toggled main,
.toggled #page-content-wrapper {
  margin-right: -240px;
}

.toggled #sidebar-wrapper .list-group {
  width: 240px;
  transition: all 0.35s ease-in-out;
}

.toggled #sidebar-wrapper {
  margin-left: -3px;
  background-color: var(--menu-bg);
  transition: all 0.35s ease-in-out;
}

.toggled .list-group-item div {
  visibility: visible;
  opacity: 1;
}

.toggled .list-group-item span {
  visibility: visible;
  opacity: 1;
}

#page-content-wrapper .navbar-toggler svg {
  transform: scaleX(1);
  transition: all 0.35s ease-in-out;
}

.toggled #page-content-wrapper .navbar-toggler svg {
  transform: scaleX(-1);
  transition: all 0.35s ease-in-out;
}

.list-group-item.active img {
  filter: brightness(50);
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  text-decoration: none;
  background-color: transparent;
  border-radius: 12px;
}

.inner-day {
  border: 1px solid var(--main-sky-blue);
  padding: 20px;
  border-radius: 12px;
}

.blue .display-4 {
  color: var(--main-sky-blue) !important;
}

.yellow .display-4 {
  color: #F9D85E !important;
}

.red .display-4 {
  color: #F5757D !important;
}

.green .display-4 {
  color: #52D987 !important;
}

.blue .display-4 {
  color: var(--main-sky-blue) !important;
}

.yellow .display-4 {
  color: #F9D85E !important;
}

.red .display-4 {
  color: #F5757D !important;
}

.green .display-4 {
  color: #52D987 !important;
}

/* #pills-tabContent {
  padding-left: 0;
  padding-right: 0;
} */

/* sidebar */
/* The side navigation menu */
#wrapper {
  overflow-x: hidden;
}

/* #sidebar-wrapper {
  background-color: var(--menu-bg);
  border: var(--white-border);
  border-radius: 0px 12px 12px 0px;
  min-height: 100vh;
  margin-left: -12.5rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  box-shadow: var(--bg-shadow-sm);
} */
#sidebar-wrapper {
  background-color: var(--main-blue);
  border: var(--white-border);
  border-radius: 0px 12px 12px 0px;
  min-height: 100vh;
  z-index: 1000;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  box-shadow: var(--bg-shadow-sm);
  transition: all 0.35s ease-in-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 85px;
  padding: 0rem 1.25rem;
  transition: all 0.35s ease-in-out;
}

#page-content-wrapper .container-fluid {
  padding: 1.875rem;
}

#page-content-wrapper {
  /* min-width: 100vw; */
  padding: 30px 20px;
}

#mainContainer,
#mainContainer main,
#page-content-wrapper {
  margin-right: 0;
  min-width: 0;
  width: 100%;
  transition: all 0.35s ease-in-out;
}

.dash-inner {
  margin-bottom: 20px;
  padding: 0 15px;
}

.menu-margin {
  margin-top: 2.5rem !important;
}

.dash-col {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
}

.balance-btn {
  margin-right: 0.9375rem;
  background: #ffffff;
  padding: 0.625rem 0.8125rem;
  border-radius: 0.625rem;
  margin-left: auto;
}

.balance-btn span {
  font-weight: 500;
  color: #7882a2;
}

.btn-icon {
  padding-right: 0.625rem;
}

.btn-icon svg {
  padding-right: 0.625rem;
}


/* #page-content-wrapper .border-bottom {
  border-bottom: 0.0625rem solid #404040 !important;
} */
/* @media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
} */

.active-menu {
  font-weight: 600;
}

/* end side nav */



/* gradient range css */

.range-slider {
  margin: 40px 0 0 0%;
}

.range-slider {
  width: 100%;
}

.range-slider__range {
  -webkit-appearance: none;
  width: calc(100% - (73px));
  height: 10px;
  border-radius: 5px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 0;
}

.range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--main-sky-blue);
  border: var(--white-border);
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

.inner-day .range-slider__range::-webkit-slider-thumb {
  display: none;
}

.range-slider__range::-moz-range-thumb {
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 50%;
  background: var(--main-sky-blue);
  cursor: pointer;
  -moz-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

.range-slider__range:focus::-webkit-slider-thumb {
  box-shadow: 2px 4px 5px rgba(15, 14, 105, 0.15);
}

::-moz-range-track {
  background: #d7dcdf;
  border: 0;
}

.offerDiv {
  background-color: #cee4ff;
  display: flex;
  justify-content: space-between;
  font-size: large;
  align-items: center;
  border-radius: 100px;
  gap: 20px;
}

.offerDiv>div {
  font-weight: 600;
  color: var(--main-blue);
}

@media (max-width:1366px) {
  #page-content-wrapper.extra-p {
    padding: 30px 20px;
  }

  html {
    font-size: 13px;
  }
}

@media (max-width:1024px) {
  .dash-head {
    margin-bottom: 20px;
  }

  .purchase-storage .nav-link {
    min-width: 100px;
  }

  #page-content-wrapper.extra-p {
    padding: 30px 10px;
  }

  .table-part .nav-pills {
    min-height: auto;
  }
}

@media (max-width:768px) {
  .form-part {
    padding-left: 15px;
    padding-right: 15px;
    margin: 30px auto 0px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .arrow-left {
    margin-left: unset;
    margin-right: 8px;
  }

  .form-field .form-group.row {
    --bs-gutter-y: 0rem;
  }

  .display-5 {
    font-size: 2.5rem;
  }
}

@media (max-width:670px) {
  .payout-modal {
    padding: 30px 25px 20px 25px !important;
  }

  .top-nav {
    box-shadow: 5px 14px 25px 0 #0d294214;
    padding: 12px 15px;
    border-radius: 0 0 13px 13px;
    background-color: white;
  }

  .top-nav #menu-toggle {
    display: inline-block;
  }

  #sidebar-wrapper {
    margin-left: -246px;
    /* visibility:hidden; */
    border: var(--white-border);
    background-color: var(--menu-bg);
    transition: all 0.35s ease-in-out;
  }

  #sidebar-wrapper .list-group {
    width: 240px;
    padding: 0rem 0.8rem;
    visibility: visible;
    opacity: 1;
    transition: all 0.35s ease-in-out;
  }

  #sidebar-wrapper .sidebar-heading {
    visibility: visible;
  }

  .toggled #sidebar-wrapper .sidebar-heading {
    visibility: visible;
  }

  .list-group-item div {
    /* opacity: 0; */
    visibility: visible;
  }

  .toggled #sidebar-wrapper {
    /* width: 240px; */
    margin-left: -3px;
    visibility: visible;
    border: var(--white-border);
  }

  .toggled #sidebar-wrapper .list-group {
    width: 240px;
    transition: all 0.35s ease-in-out;
  }

  #page-content-wrapper.extra-p {
    padding: 0;
  }
}

@media (max-width:588px) {
  .offerDiv div:first-child {
    font-size: medium;
  }
}

@media (max-width:580px) {
  .plan-wrapper {
    flex-wrap: wrap;
    gap: 9px;
    justify-content: center;
    text-align: center;
  }

  .cta-white {
    font-size: 14px;
  }

  #checkoutPayBtn {
    font-size: 18px;
  }

  .form-cta-btn a {
    font-size: 14px;
  }

  #sidebar-wrapper .list-group {
    width: 240px;
    padding: 0rem 0.8rem;
    transition: all 0.35s ease-in-out;
  }

  .dash-box {
    background-color: var(--main-white);
    border: var(--white-border);
    padding: 20px 15px;
    box-shadow: var(--bg-shadow-sm);
    border-radius: 15px;
  }

  .dash-tabs .nav-link {
    min-width: auto !important;
  }

  .password-m .modal-content {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width:500px) {
  .offerDiv {
    flex-direction: column;
    gap: 5px;
  }

  .offerDiv div:first-child {
    font-size: medium;
    padding: 5px 15px 0px 15px;
    text-align: center;
  }
}

@media (max-width:435px) {
  #monthly-select {
    width: 100%;
  }
}

@media (max-width: 400px) {
  #pills-tab-pricing li:first-child {
    width: 100%;
  }

  #pills-tab-pricing li:not(:first-child) {
    width: 47.5%;
  }
}

@media (max-width:349px) {
  #pills-tab-pricing li:not(:first-child) {
    width: 46%;
  }
}

/* range-dif */
.range-dif-part input {
  width: 100% !important;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
  .range-dif-part input[type='range'] {
    overflow: hidden;
    width: 80px;
    -webkit-appearance: none;
    background-color: #d6d6d6;
    border-radius: 16px;
  }

  .range-dif-part input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #359BEC;
    margin-top: -1px;
  }

  .yellow .range-dif-part input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #F9D85E !important;
    margin-top: -1px;
  }

  .red .range-dif-part input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #F5757D !important;
    margin-top: -1px;
  }

  .green .range-dif-part input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #52D987 !important;
    margin-top: -1px;
  }

  .range-dif-part input[type='range']::-webkit-slider-thumb {
    width: 00px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #434343;
    box-shadow: -80px 0 0 80px #359BEC;
  }

  .yellow .range-dif-part input[type='range']::-webkit-slider-thumb {
    width: 00px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #434343;
    box-shadow: -80px 0 0 80px #F9D85E;
  }

  .red .range-dif-part input[type='range']::-webkit-slider-thumb {
    width: 00px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #434343;
    box-shadow: -80px 0 0 80px #F5757D;
  }

  .green .range-dif-part input[type='range']::-webkit-slider-thumb {
    width: 00px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #434343;
    box-shadow: -80px 0 0 80px #52D987;
  }
}

/** FF*/
.range-dif-part input[type="range"]::-moz-range-progress {
  background-color: #43e5f7;
}

.range-dif-part input[type="range"]::-moz-range-track {
  background-color: #9a905d;
}

/* IE*/
.range-dif-part input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7;
}

.range-dif-part input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
}


/* loader */
#loader {
  background: #19233e url(../images/dah-logo.svg) center no-repeat 6.25rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
}

.loader-container {
  align-items: center;
  background: #1d2b52 url(../images/dah-logo.svg) no-repeat center/4.375rem;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.loading-screen {
  height: 9.375rem;
  width: 9.375rem;
  border-radius: 50%;
  border: 0.0625rem solid var(--main-sky-blue);
  border-right: none;
  border-bottom: none;
  animation-name: spin, fade;
  animation-duration: 3s, 3s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1), linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1170deg);
  }
}

@keyframes fade {

  0%,
  100%,
  70% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

/* scroll */


/* scroll bar */
/* width */
::-webkit-scrollbar {
  width: 0.3125rem;

}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-blue);

}

/* width */
.table-responsive::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.3125rem;
}

/* Track */
.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.table-responsive::-webkit-scrollbar-thumb {
  background: var(--main-blue);
  border-radius: 0.5rem;
}


.dash-form-part .title {
  font-size: 15px;
  color: var(--main-blue);
}

.swap {
  gap: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
  color: var(--main-blue);
  cursor: pointer;
}

.swaping {
  gap: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
  color: var(--main-blue);
  cursor: not-allowed;
}

.swap-disable {
  gap: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
  color: var(--main-blue);
  cursor: not-allowed;
  opacity: 0.4;
}


.description {
  text-indent: 50px;
}

.soldout {
  background-color: #dc3545;
  cursor: not-allowed;
}